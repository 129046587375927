import { css } from '@emotion/css';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
    FooterNavCol,
    FooterNavRow,
    StyledFooter
} from './styles/Footer.styled';

interface Item {
    title: string
    links: NavLink[]
}

interface NavLink {
    name?: string
    url: string
    img?: string
    target?: string
}

const Footer = () => {
    const { t } = useTranslation()

    const nav: Item[] = [
        {
            title: t('Products'),
            links: [
                { name: t('PoweLego'), url: 'https://www.powerlego.com/', target: '_blank' },
                { name: t('EIQhome'), url: 'https://www.eiqhome.com/', target: '_blank' },
                { name: t('EIQShopping'), url: `https://www.eiqshopping.com/`, target: '_blank' },
                { name: t('ESIID.io'), url: `https://www.esiid.io/`, target: '_blank' },
                { name: t('BillReader'), url: `https://www.billreader.com/`, target: '_blank' },
            ],
        },
        // {
        //     title: t('Company'),
        //     links: [
        //         { name: t('About Us'), url: '#', },
        //         { name: t('Terms of Use'), url: '#', },
        //         { name: t('Privacy Policy'), url: '#', },
        //     ],
        // },
        {
            title: t('Support'),
            links: [
                { name: 'Contact Us', url: '/contact' },
                { name: t('Terms of Service'), url: '/terms_of_service' },
                { name: t('Privacy Policy'), url: '/privacy_policy' },
            ],
        },
        {
            title: t('Secure'),
            links: [
                { name: 'amazon', img: '/images/footer_logo.png', url: '#' },
                { name: 'eiqdigital', img: '/images/logo_eiqdigital.png', url:'https://www.eiqdigital.com/'}
            ],
        },
    ]

    const location = useLocation()
    const isHome = location.pathname === '/'

    return (
        <StyledFooter style={isHome ? {} : { background: '#f6f6f5' }}>
            <Container>
                <FooterNavRow>
                    {/* <div className={css`
                        flex:0 0 300px;
                        img{
                            height:50px;
                        }
                        @media (max-width:990px){
                            flex:1;
                            display:flex;
                            align-items:center;
                            flex-direction:column;
                            img{
                                height:25px;
                            }
                            p{
                                font-size:14px;
                                text-align:center;
                            }
                        }
                    `}>
                        <img src="https://www.esiid.io/static/media/eiqdigtal_logo.45e730d8ad3b788824f9.png" alt=""
                            style={{
                                marginBottom: 10,
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                window.open('https://www.eiqdigital.com/')
                            }}
                        />
                        <p>Foundational building block enabling the digital transformation of the retail energy industry.</p>
                    </div> */}
                    <div style={{ display: 'flex', flex: 1 }}>
                        {nav.map((val, idx) => (
                            <FooterNavCol key={idx}>
                                <h6>{val.title}</h6>
                                {val.links.map((item, itemIdx) => {
                                    return (
                                        <div key={itemIdx}>
                                            {item.img ? <img src={item.img} alt={item.name} className={css`
                                                width:78px;
                                                @media (max-width:990px){
                                                    width:80%;
                                                }
                                            `} />
                                                : <Link to={item.url} rel="noreferrer" target={item.target || '_self'}>
                                                    {item.name}
                                                </Link>}
                                        </div>
                                    )
                                })}
                            </FooterNavCol>
                        ))}
                    </div>
                </FooterNavRow>
            </Container>
        </StyledFooter>
    )
}

export default Footer
